:root {
  --primary-color: #0052ff;
  --primary-hover-color: #1652f0;
  --light-blue: #08bfe6;
  --black-color: #000000;
  --dark-color: #333333;
  --gray-color: #939393;
  --bg-color: #f6f9fc;
  --light-color: #f1f3f5;
  --danger-color: #f57a8c;
  --red-color: #ff213f;
  --success-color: #00e396;
  --yellow-color: #feb019;
  --icon-color: #e2e2e2;
  --svg-color: #0052ff;
  --blue-color: #008ffb;
  --purple-color: #775dd0;
  --light-dark: #a4a4a4;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  background-color: var(--bg-color) !important;
  font-family: 'Open Sans', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
}

#__react-alert__ div {
  z-index: 10000 !important;
}

.w-60 {
  width: 60% !important;
}

.btn {
  padding: 0 16px !important;
  font-size: 13px;
  line-height: 18px;
  align-items: center;
  height: 32px;
  display: inline-flex;
}

.btn:focus,
.btn.focus {
  outline: none;
  box-shadow: none;
}

.delete-icon {
  //color: red;
}

.delete-icon:hover,
.add-icon:hover {
  cursor: pointer;
}

.MuiBox-root {
  padding: 0 !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.divider {
  background: #d5d5d5;
  height: 1px;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.dotted-divider {
  border-style: dashed;
  margin-top: 0.8rem;
  border-width: 1px;
  margin-bottom: 0.8rem;
}

.hover {
  transition: all 0.1s ease-in-out;
}

.hover:hover {
  cursor: pointer;
  background: var(--bg-color);
}

.hover:hover {
  cursor: pointer;
  background: var(--bg-color);
}

.hover-link {
  color: var(--light-dark);
  text-decoration: none;
  font-weight: 700;
  transition: all 0.1s ease-in-out;
}

.hover-link:hover {
  cursor: pointer;
  color: var(--primary-color);
}

.success {
  font-size: 16px;
  color: var(--success-color);
}

.danger {
  font-size: 16px;
  color: var(--red-color);
}

.header_icons {
  i {
    border-radius: 50%;
    color: white;
    padding: 10px;
  }

  .customers {
    background-color: var(--blue-color);
  }

  .sales {
    background-color: var(--success-color);
  }

  .paid {
    background-color: var(--purple-color);
  }

  .due {
    background-color: var(--yellow-color);
  }

  .stock {
    background-color: var(--red-color);
  }
}

///////////////////////// LOGIN - REGISTER////////////////////////////
#login-register {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;

  .logo-container {
    position: relative;
    background: url('../assets/banners/logo_banner.jpg') no-repeat center;
    background-size: cover;
  }

  .overlay {
    position: absolute;
    bottom: 0;
    top: 0;
    opacity: 0.4;
    background-color: black;
    color: #f1f1f1;
    right: 0;
    left: 0;
    transition: 0.5s ease;
  }

  .gray-login-text {
    display: inline-block;
    color: #3f3f3f;
    font-weight: bold;
    margin-right: 8px;
    font-size: 14px;
  }

  .MuiInputBase-input {
    height: 0.1876em;
  }

  @media (max-width: 992px) {
    .logo-container {
      display: none;
    }
  }

  .login-card-wrapper {
    width: 65% !important;
  }

  .copyright {
    margin-top: 5rem;
  }

  @media (max-width: 536px) {
    .login-wrapper {
      padding-right: 0 !important;
    }
    .login-card-wrapper {
      flex: 0 0 85% !important;
      max-width: 85% !important;
    }
  }
}

///////////////////////  LEFT SIDE BAR  ///////////////////////

.bottom_menus {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;

  a {
    font-size: 12px;
    margin-bottom: 8px !important;
  }
}

.fa-sign-out-alt {
  color: var(--black-color);
  margin-left: 1rem;
  padding: 6px;
  transition: all 0.1s ease-in-out;
}

.fa-sign-out-alt:hover {
  border-radius: 50%;
  background: var(--light-color);
  color: var(--primary-color);
}

.leftSideMenu {
  .fa,
  .far {
    color: var(--black-color);
    margin-right: 20px;
    display: inline-block;
    position: relative;
    top: 1px;
    margin-top: 0.2rem;
    background: var(--light-color);
    padding: 10px;
    border-radius: 50%;
  }

  nav {
    max-height: 800px;
    overflow: auto;
    padding-top: 0;

    .a {
      display: block;
      text-decoration: none;
      margin-left: 10px;
      margin-right: 10px;
      color: var(--black-color);
      padding: 6px;
      font-weight: 700;
      margin-bottom: 0.5rem;
      border-radius: 10px;
      transition: all 0.1s ease-in-out;
    }

    .a:hover {
      background: var(--light-color);
    }

    .a.active,
    .a:active,
    .a.active .fa,
    .a.active .far {
      color: var(--primary-color);
    }
  }
}

/////////////////// BREADCRUMB /////////////////////
#breadcrumb-container {
  .breadcrumb {
    border-radius: 0;
    margin-bottom: 0;
    background: none;
    padding: 0;
    font-size: 13px;
    font-weight: 400;

    a {
      text-decoration: none;
      color: var(--icon-color);
    }

    .breadcrumb-item {
      margin-top: 2px;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      content: '\f054';
      font-family: 'Font Awesome 5 Free', serif;
      padding: 0 0.9rem;
      font-weight: 700;
      font-size: 11px;
      color: var(--icon-color);
    }

    .home {
      a {
        color: var(--primary-hover-color);
      }

      margin-top: 0;
    }

    .active {
      a {
        color: var(--black-color);
        font-weight: 700;
      }
    }

    .MuiSvgIcon-root {
      font-size: 20px;
    }
  }
}

/////////////////// FORM /////////////////////
.form-group {
  label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .form-check {
    margin-top: 0.2rem !important;

    label {
      margin-bottom: 0;
    }
  }

  p {
    margin-top: 0.3rem !important;
  }
}

///////////////////////// HOME ///////////////////////
#card-content {
  background: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(#000, 0.075);
  border-radius: 10px;
  border: 0 !important;
  padding: 1rem;

  .card-header {
    background-color: #fff !important;
    border-radius: 10px 10px 0 0;
    border-bottom: 0;
    padding: 0.5rem 0;
    //box-shadow: 0 .125rem .25rem rgba(#000, .075);

    p {
      opacity: 0.6;
      margin-bottom: 0.25rem;
    }

    //h5, p, h4, h6 {
    //  margin-bottom: 0;
    //}
  }

  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0;
  }

  .list-group {
    border-radius: 0;
    margin-bottom: 0.8rem;
  }

  .list-group-item {
    border: none;
    padding: 0.7rem 0;
  }

  .MuiToolbar-root {
    padding-left: 0.25rem !important;
  }
}

///////////////////////// ACCORDION /////////////////////
.MuiPaper-elevation1 {
  box-shadow: 0 0.125rem 0.25rem rgba(#000, 0.075) !important;
  padding: 0.5em;

  h5 {
    margin: 0;
  }
}

///////////////////////// INVENTORY ///////////////////////
.image_area {
  border: 1px solid var(--icon-color) !important;
  background: var(--light-color);
  box-shadow: inset 0 0.5rem 1rem rgba(0, 0, 0, 0.075) !important;
}

.add_image_bck {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.add_image_bck:hover {
  cursor: pointer;
}

.add_image_btn {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  z-index: 90;
  opacity: 0;
  top: 0;
}

.add_image_btn:hover {
  cursor: pointer;
}

.delete-image-icon {
  color: red;
  position: absolute;
  top: 2%;
  right: 2%;
  z-index: 100;
}

.heading_columns {
  border-bottom: 1px solid var(--icon-color);

  p {
    margin-bottom: 0.5rem;
    font-size: 16px;
    opacity: 1 !important;
  }

  h4 {
    color: var(--primary-color);
  }

  .border-right {
    border-right: 1px solid var(--icon-color);
  }
}

.list-product-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 1px solid var(--icon-color) !important;
}

.stock-product-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 1px solid var(--icon-color) !important;
}

.multiple_options {
  margin-bottom: 0;
  display: inline-block;
  border: 1px solid var(--icon-color);
  padding: 4px;
  margin-right: 4px;
  margin-top: 4px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
}

.active_status {
  background-color: #00cc00;
  display: inline;
  padding: 4px;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  font-size: 13px;
}

.inactive_status {
  background-color: #ff0000;
  display: inline;
  padding: 4px;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  font-size: 13px;
}

////////////////////////////// ADMINISTRATION //////////////////////////
.company-logo {
  transition: all 0.3s ease-in;
  position: relative;
  display: inline-block;
  transform: scale(0.9);
  border-radius: 50%;

  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
  }

  .bottom-logo-section {
    position: absolute;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    bottom: 0;
    display: none;
    left: 0;
    top: 0;
    right: 0;
    border-radius: 50%;
    font-weight: bold;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.company-logo:hover {
  cursor: pointer;
  transform: scale(1);

  .bottom-logo-section {
    display: flex;
  }
}

.invoice-logo {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

////////////////////////////// NOT FOUND //////////////////////////
.not-found {
  height: calc(100vh - 10rem);
  text-align: center;

  h1 {
    font-size: 8em;
  }
}

////////////////////////////// EXTRAS //////////////////////////
.paid {
  color: white;
  background-color: rgba(0, 166, 90, 0.8);
  padding: 4px 0.5rem;
  border-radius: 4px;
  font-size: 12px;
}

.advance {
  color: white;
  background-color: rgb(27, 100, 253);
  padding: 4px 0.5rem;
  border-radius: 4px;
  font-size: 12px;
}

.unpaid {
  color: white;
  background-color: rgba(143, 140, 208, 0.8);
  padding: 4px 0.5rem;
  border-radius: 4px;
  font-size: 12px;
}

.partial {
  color: white;
  background-color: rgba(222, 166, 0, 0.8);
  padding: 4px 0.2rem;
  border-radius: 4px;
  font-size: 12px;
}

.pending {
  color: white;
  background-color: rgba(18, 145, 255, 0.8);
  padding: 4px 0.5rem;
  border-radius: 4px;
  font-size: 12px;
}

.packed {
  color: white;
  background-color: rgba(239, 131, 233, 0.8);
  padding: 4px 0.5rem;
  border-radius: 4px;
  font-size: 12px;
}

.cancelled {
  color: white;
  background-color: rgba(221, 75, 57, 0.8);
  padding: 4px 0.5rem;
  border-radius: 4px;
  font-size: 12px;
}

.inactive {
  color: #ffffff;
  background-color: rgba(171, 171, 171, 0.8);
  padding: 4px 0.5rem;
  border-radius: 4px;
  font-size: 12px;
}

.saleCheckbox {
  .MuiSvgIcon-root {
    fill: #1652f0;
  }
}

.text-bg-blue {
  color: white;
  background-color: rgba(18, 145, 255, 0.6);
}

.income-expense {
  p {
    color: black;
    opacity: 1 !important;
  }
}

.table {
  tr {
    th,
    td {
      padding: 1rem;
    }
  }
}

.btn-block {
  display: block;
  width: 100%;
}

.search-button {
  padding: inherit !important;
}

.sales-table {
  th {
    min-width: 130px !important;
  }

  th:last-child,
  th:first-child {
    min-width: 0 !important;
  }
}

.variation-price {
  padding: 10px;
}

.replace-btn {
  height: 25px;
  margin-top: 0.5rem;
}

// table icon style e.g print
.table-icon {
  svg {
    color: #131313;
    font-size: 19px;
  }
}

.MuiIconButton-root {
  font-size: 0.5rem !important;
}

.search-bar {
  top: 6px;
  left: 120px;

  .MuiSvgIcon-root {
    font-size: 20px !important;
  }
}

.invoice-table {
  th:nth-last-child(2) {
    display: none;
  }

  tr {
    td:nth-last-child(2) {
      display: none;
    }
  }
}

@import 'PosStyles';
@import 'PrintStyles';
