@media print {
  html,
  body {
    height: max-content;
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  font,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    font-size: 11px !important;
  }

  h1 {
    font-size: 15px !important;
  }

  h2,
  h3 {
    font-size: 14px !important;
  }

  h4,
  h5 {
    font-size: 13px !important;
  }
}
