//Bootstrap Overrides
$primary: #0052ff;
$link-hover-color: #2c6eff;
//$headings-color: #414E6A;
$headings-color: #171717;
//$body-color: #404E67;
$body-color: #171717;
$body-bg: #f5f7fa;
$danger: #ff7588;
$success: #16d39a;
$warning: #ffa87d;

$h1-font-size: 2.1875rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.53125rem;
$h4-font-size: 1.3125rem;
$h5-font-size: 1.15rem;
$h6-font-size: 0.875rem;

$btn-font-size: 0.875rem;
$input-height-sm: 25px;

// Option A: Include all of Bootstrap
@import '../node_modules/bootstrap/scss/bootstrap';

@import 'styles/Style';
