.product-search-input {
  .MuiOutlinedInput-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.product-search-btn {
  height: 40px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.products {
  padding: 1rem;
  height: 720px;
  overflow: auto;

  .product {
    border-radius: 8px;
    border: 1px solid var(--light-color);
    transition: all 0.1s ease-in;

    img {
      width: 100%;
      height: 130px;
      border-radius: 8px;
      object-fit: cover;
    }

    .content-bottom {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 10px;
      background-color: #3d3d3d;
      color: white !important;

      h6 {
        color: white;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      p {
        margin-bottom: 4px;
      }

      span {
        border: 0.5px solid var(--bg-color);
        padding: 0 5px;
        font-weight: 500;
        font-size: 12px;
        color: var(--bg-color);
        margin-right: 0.5rem;
        border-radius: 8px;
      }
    }
  }

  .product:hover,
  .active {
    cursor: pointer;
    border: 1px solid var(--light-blue);

    .content-bottom {
      background-color: var(--light-blue);

      span {
        border: 0.5px solid var(--bs-white);
        color: var(--bs-white);
      }
    }
  }
}

.carts {
  #card-content {
    padding: 0;
  }

  .table {
    overflow: hidden;
    border-radius: 10px;

    .carts-head {
      background-color: var(--primary-color);
      color: white;
      width: 100%;

      th {
        padding: 14px;
        text-align: center;
      }
    }

    thead,
    tbody tr {
      display: table;
      table-layout: fixed;
    }

    tbody {
      height: 480px;
      overflow: auto;
      display: block;

      tr {
        border: 2px solid var(--light-color);

        td {
          border: none;
          font-weight: 500;
          padding: 1rem;
          vertical-align: middle;
          border-right: 2px solid var(--light-color);

          button {
            span {
            }
          }

          .qty-input::-webkit-outer-spin-button,
          .qty-input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input {
            padding: 4px;
            margin: 0 4px;
          }

          .qty-input {
            width: 50px;
          }

          .discount-input {
            width: 100px;
          }

          .product-name {
            width: 183px;
          }
        }
      }
    }
  }

  .payment-container {
    background-color: #1a2eab;
    padding: 1.5rem;
    color: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    .content {
      padding: 1.2rem;
      background-color: white;
      border-radius: 10px;
      text-align: center;
      transition: all 0.2s ease-in-out;
      transform: scale(0.9);

      i {
        font-size: 30px;
        margin-bottom: 20px;
      }

      h5 {
        margin: 0;
      }
    }

    .content:hover {
      cursor: pointer;
      transform: scale(1.1);
      box-shadow: inset 0 0.5rem 1rem rgba(0, 0, 0, 0.075) !important;
    }

    .cancel {
      width: 120px;
      color: #eb5757;

      h5 {
        color: #eb5757;
      }
    }

    .save {
      width: 120px;
      color: #1a2eab;

      h5 {
        color: #1a2eab;
      }
    }
  }
}
